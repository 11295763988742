<template>
    <div class="search-result" data-cy="search-result-component">
        <Empty class="no-result" v-if="neverFiltered && !comicList.length" />
        <div class="result" v-else>
            <div class="header" @click="onFilterClick">
                <div class="filter">
                    <div :class="['icon', { open: showTabs }]">
                        <img src="../../assets/arrow.png" />
                    </div>
                    筛选
                </div>
            </div>

            <div v-show="showTabs">
                <Tabs
                    :options="genderOptions"
                    label="性格偏好"
                    v-model="options.gender"
                    class="result-tabs"
                    @change="onOptionsChange"
                />
                <Tabs
                    :options="updateStatusOptions"
                    label="是否完结"
                    v-model="options.updateStatus"
                    class="result-tabs"
                    @change="onOptionsChange"
                />
                <Tabs
                    :options="labelOptions"
                    label="分类"
                    :active="options.labels"
                    class="result-tabs"
                    multiple
                    @change="onLabelsChange"
                />
                <Tabs
                    :options="updateTimesOptions"
                    label="更新时间"
                    v-model="options.updateSince"
                    class="result-tabs"
                    @change="onOptionsChange"
                />
            </div>

            <div class="list" v-if="comicList.length">
                <Comic
                    v-for="item in comicList"
                    :key="item.topicId"
                    :comic="item"
                    class="comic-item"
                    @readComic="readComic"
                    :clicked="clickedList.includes(item.topicId)"
                />
                <div class="tips" v-if="!hasMore"><span>到底了</span></div>
            </div>

            <Empty class="no-result" v-else title="暂无数据，换个条件试试吧~" />
        </div>
        <div class="back-top" v-show="showBackTop" @click.prevent="backTop">
            <img src="../../assets/backtop.png" />
        </div>
    </div>
</template>
<script>
import Comic from '../Comic/index.vue';
import Empty from '../Empty/index.vue';
import Tabs from '../Tabs/index.vue';
import { throttle } from '@kk/utils';

const defaultOptions = (value = -1) => {
    return {
        label: '不限',
        value,
    };
};

export default {
    name: 'SearchResult',
    components: {
        Comic,
        Empty,
        Tabs,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        neverFiltered: {
            type: Boolean,
            default: true,
        },
        comicList: {
            type: Array,
            default: () => [],
        },
        updateTimes: {
            type: Array,
            default: () => [],
        },
        genderEnums: {
            type: Array,
            default: () => [],
        },
        updateStatusEnums: {
            type: Array,
            default: () => [],
        },
        labels: {
            type: Array,
            default: () => [],
        },
        hasMore: {
            type: Boolean,
            default: false,
        },
        filterOptions: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            clickedList: [],
            showTabs: true,
            options: this.filterOptions,
            scrollDom: null,
            showBackTop: false,
        };
    },
    computed: {
        genderOptions() {
            return [defaultOptions()].concat(
                this.genderEnums.map((item) => {
                    return {
                        label: item.desc,
                        value: item.gender,
                    };
                })
            );
        },
        updateStatusOptions() {
            return [defaultOptions()].concat(
                this.updateStatusEnums.map((item) => {
                    return {
                        label: item.desc,
                        value: item.updateStatus,
                    };
                })
            );
        },
        labelOptions() {
            return [defaultOptions('不限')].concat(
                this.labels.map((item) => {
                    return {
                        label: item,
                        value: item,
                    };
                })
            );
        },
        updateTimesOptions() {
            return [defaultOptions()].concat(
                this.updateTimes.map((item) => {
                    return {
                        label: item.desc,
                        value: item.updateSince,
                    };
                })
            );
        },
    },
    watch: {
        filterOptions(value) {
            this.options = value;
        },
    },
    beforeUnmount() {
        this.removeScroll();
    },
    mounted() {
        this.$nextTick(() => {
            this.scrollDom = this.$el.parentNode;
            this.bindScroll();
        });
    },
    methods: {
        backTop() {
            this.scrollDom.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
        backTopScrollHandler() {
            const { clientHeight = 0, scrollTop = 0 } = this.scrollDom || {};

            this.showBackTop = scrollTop > 1.5 * clientHeight;
        },
        removeScroll() {
            this.scrollDom.removeEventListener('scroll', this.scrollHandle);
        },
        bindScroll() {
            this.scrollHandle = throttle(this.scrollHandle, 300);
            this.scrollDom.addEventListener('scroll', this.scrollHandle);

            this.backTopScrollHandler = throttle(this.backTopScrollHandler, 300);
            this.scrollDom.addEventListener('scroll', this.backTopScrollHandler);
        },
        scrollHandle() {
            const { clientHeight, scrollTop, scrollHeight } = this.scrollDom || {};
            const dis = scrollHeight - clientHeight - scrollTop;

            if (!this.loading && this.hasMore && dis <= 200) {
                this.$emit('searchMore');
            }
        },
        readComic(comic) {
            if (!this.clickedList.includes(comic.topicId)) {
                this.clickedList.push(comic.topicId);
            }

            this.$router.push({
                name: 'ComicRead',
                query: { topicId: comic.topicId },
                params: {
                    TriggerPage: '搜索页',
                    originForm: '搜索结果',
                    payInfo: {
                        ModuleID: -1,
                        ModuleType: '搜索结果',
                    },
                },
            });
        },
        onOptionsChange() {
            this.$emit('filterOptionsChange', this.options);
        },
        onLabelsChange(value) {
            const last = value[value.length - 1];

            if (!last || last === '不限') {
                this.options.labels = ['不限'];
                this.onOptionsChange();
                return;
            }

            this.options.labels = value.filter((item) => item !== '不限');
            this.onOptionsChange();
        },
        onFilterClick() {
            this.showTabs = !this.showTabs;
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

.result {
    .header {
        display: flex;
        align-items: center;
        .vws(height, 80px);
        .vws(font-size, 24px);
        .vws(padding-left, 32px);
        .vws(padding-right, 32px);
    }

    .filter {
        display: flex;
        align-items: center;
    }

    .open {
        transform: rotate(90deg);
    }

    .icon {
        .vws(width, 32px);
        .vws(height, 32px);
        .vws(margin-right, 10px);

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.result-tabs {
    .vws(margin-bottom, 56px);
    .vws(padding-left, 32px);
    .vws(padding-right, 32px);

    &:not(:last-child) {
        .vws(margin-bottom, 24px);
    }
}

.no-result {
    .vws(margin-top, 104px);
    .vws(padding-bottom, 104px);
}

.comic-item {
    .vws(padding-left, 32px);
    .vws(padding-right, 32px);
}

.list {
    .vws(padding-bottom, 105px);
}

.tips {
    display: flex;
    margin: 16px 0;
    border-top: 0;
    border-top-color: #f5f5f5;
    text-align: center;
    white-space: nowrap;
    color: #999;
    box-sizing: border-box;
    .vws(padding-left, 24px);
    .vws(padding-right, 24px);

    span {
        display: inline-block;
        .vws(padding-left, 60px);
        .vws(padding-right, 60px);
        .vws(font-size, 24px);
    }

    &::before {
        position: relative;
        top: 50%;
        width: 50%;
        border-top: 1px solid transparent;
        border-top-color: inherit;
        border-bottom: 0;
        transform: translateY(50%);
        content: '';
    }

    &::after {
        position: relative;
        top: 50%;
        width: 50%;
        border-top: 1px solid transparent;
        border-top-color: inherit;
        border-bottom: 0;
        transform: translateY(50%);
        content: '';
    }
}

.back-top {
    .vws(width, 92px);
    .vws(height, 92px);

    position: fixed;
    .vws(bottom, 318px);
    .vws(right, 24px);

    /* stylelint-disable-next-line no-descending-specificity */
    > img {
        width: 100%;
        height: 100%;
    }
}
</style>
