<template>
    <div class="before-search" data-cy="before-search-component">
        <div class="history">
            <div class="title-bar">
                <div class="title">搜索历史</div>
                <div class="icon delete-icon" @click.passive="clearHistory">
                    <img src="../../assets/delete-icon.png" />
                </div>
            </div>

            <div class="history-tag-group" v-if="historyList.length">
                <div class="history-tag-wrapper" v-for="item in historyList" :key="item">
                    <div class="history-tag" @click="search(item)">
                        <div class="tag-name">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="empty" v-else>暂无数据</div>
        </div>

        <div class="hot-search">
            <div class="title-bar">
                <div class="title">近期热搜</div>
                <div class="icon refresh-icon" @click.passive="refreshHotList">
                    <img src="../../assets/refresh-icon.png" />
                </div>
            </div>

            <div class="hot-search-tag-group" v-if="hotSearchList.length">
                <div class="left-group">
                    <div
                        v-for="(item, index) in hotSearchDisplayList[0]"
                        :key="item.topicId"
                        class="hot-search-tag-wrapper"
                        @click="readComic(item)"
                    >
                        <div class="hot-search-tag-name">
                            {{ item.topicName }}
                        </div>
                        <div class="number-tag" v-if="chunkIndex === 0 && index <= 1">
                            NO.{{ index === 0 ? 1 : 3 }}
                        </div>
                    </div>
                </div>
                <div class="right-group">
                    <div
                        v-for="(item, index) in hotSearchDisplayList[1]"
                        :key="item.topicId"
                        class="hot-search-tag-wrapper"
                        @click="readComic(item)"
                    >
                        <div class="hot-search-tag-name">
                            {{ item.topicName }}
                        </div>
                        <div class="number-tag" v-if="chunkIndex === 0 && index === 0">NO.2</div>
                    </div>
                </div>
            </div>
            <div class="empty" v-else>暂无数据</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BeforeSearch',
    props: {
        historyList: {
            type: Array,
            default: () => [],
        },
        hotSearchList: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            chunkIndex: 0,
            chunkLength: 8,
        };
    },
    computed: {
        // 将近期热搜分页
        hotSearchListChunks() {
            const chunks = [];
            let i = 0;

            while (i * this.chunkLength < this.hotSearchList.length) {
                chunks.push(
                    this.hotSearchList.slice(i * this.chunkLength, (i + 1) * this.chunkLength)
                );
                i++;
            }

            return chunks;
        },
        // 当前显示的近期热搜
        hotSearchDisplayList() {
            const defaultValue = [[], []];

            return (
                this.hotSearchListChunks[this.chunkIndex]?.reduce((acc, cur, index) => {
                    if (index % 2 === 0) {
                        return [acc[0].concat([cur]), acc[1]];
                    }

                    return [acc[0], acc[1].concat([cur])];
                }, defaultValue) || defaultValue
            );
        },
    },
    methods: {
        readComic(comic) {
            this.$router.push({
                name: 'ComicRead',
                query: { topicId: comic.topicId },
                params: {
                    TriggerPage: '搜索页',
                    originForm: '搜索推荐',
                    payInfo: {
                        ModuleID: -1,
                        ModuleType: '搜索推荐',
                    },
                },
            });
        },
        search(name) {
            this.$emit('search', name);
        },
        clearHistory() {
            this.$emit('clearHistory');
        },
        refreshHotList() {
            if (this.chunkIndex + 1 <= this.hotSearchListChunks.length - 1) {
                this.chunkIndex++;
                return;
            }

            this.chunkIndex = 0;
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

.before-search {
    .vws(padding-left, 32px);
    .vws(padding-right, 32px);
}

.icon {
    display: flex;
    align-items: center;
    justify-self: center;

    img {
        width: 100%;
        height: 100%;
    }
}

.title-bar {
    display: flex;
}

.title {
    .vws(font-size, 28px);
    .vws(line-height, 34px);
}

.delete-icon,
.refresh-icon {
    .vws(width, 32px);
    .vws(height, 32px);

    margin-top: auto;
    margin-left: auto;
}

.empty {
    .vws(font-size, 24px);

    color: #333;
    opacity: 0.5;
}

.history {
    .vws(margin-bottom, 62px);

    .title-bar {
        .vws(margin-bottom, 26px);
    }

    .empty {
        .vws(padding-top, 15px);
    }

    .history-tag-group {
        display: flex;
        flex-wrap: wrap;
        .vws(margin-left, -12px);
        .vws(margin-right, -12px);
        .vws(margin-top, -10px);
        .vws(margin-bottom, -10px);
        .vws(max-height, 180px);

        overflow: hidden;

        .history-tag-wrapper {
            .vws(padding-left, 12px);
            .vws(padding-right, 12px);
            .vws(padding-top, 10px);
            .vws(padding-bottom, 10px);
        }

        .history-tag {
            .vws(font-size, 24px);

            display: inline-flex;
            justify-content: center;
            align-items: center;
            .vws(padding-left, 24px);
            .vws(padding-right, 24px);
            .vws(padding-top, 15px);
            .vws(padding-bottom, 15px);

            background-color: #f7f7f8;
            .vws(border-radius, 32px);
            .vws(max-width, 216px);

            box-sizing: border-box;

            .tag-name {
                .ellipsis-ln(1);

                color: #333;
            }
        }
    }
}

.hot-search {
    .title-bar {
        .vws(margin-bottom, 42px);
    }

    .hot-search-tag-group {
        display: flex;

        .left-group {
            .vws(width, 366px);
            .vws(padding-right, 85px);

            border-right: #efefef 1px solid;
            box-sizing: border-box;

            .hot-search-tag-name {
                .vws(max-width, 281px);
            }
        }

        .right-group {
            box-sizing: border-box;
            .vws(padding-left, 42px);

            .hot-search-tag-name {
                .vws(max-width, 259px);
            }
        }

        .hot-search-tag-wrapper {
            position: relative;
            display: flex;
            align-items: center;

            &:not(:last-child) {
                .vws(margin-bottom, 26px);
            }
        }

        /* stylelint-disable-next-line no-descending-specificity */
        .hot-search-tag-name {
            .vws(font-size, 24px);
            .ellipsis-ln(1);

            color: #333;
            .vws(margin-right, 12px);
        }
    }

    .number-tag {
        .vws(border-radius, 4px);

        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-family: 'PingFang SC';
        font-weight: 500;
        color: #f44;
        background-color: #fef1f1;
        .vws(font-size, 16px);
        .vws(padding-left, 10px);
        .vws(padding-right, 10px);
        .vws(padding-top, 2px);
        .vws(padding-bottom, 2px);
        .vws(height, 24px);
        .vws(line-height, 20px);
    }
}
</style>
