<template>
    <div class="update-tag">
        {{ updateDesc }}
    </div>
</template>

<script>
export default {
    props: {
        updateDesc: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="less">
@import '~@/assets/vws.less';

.update-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .convert(8px) [ @vw];
    height: .convert(36px) [ @vw];
    font-size: .convert(20px) [ @vw];
    font-weight: 600;
    border-radius: .convert(4px) [ @vw];
    color: #ff858a;
    background-color: #fff8f8;
    box-sizing: border-box;
}
</style>
