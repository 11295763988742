<template>
    <div class="wrapper" data-cy="wrapper-component">
        <div class="header">
            <div class="icon back-icon" @click.passive="onBack">
                <img src="./assets/back-icon.png" />
            </div>
            <SearchInput
                ref="searchInput"
                clearable
                v-model="searchValue"
                class="search-input"
                placeholder="请输入漫画名"
                @clear="onClearSearchInput"
                @search="onSearch"
            />
            <div class="search-button" @click.passive="onSearch">搜索</div>
        </div>

        <SearchResult
            v-if="isSearched"
            :comicList="searchResult"
            :genderEnums="genderEnums"
            :labels="labels"
            :updateStatusEnums="updateStatusEnums"
            :updateTimes="updateTimes"
            :neverFiltered="neverFiltered"
            :hasMore="page + 1 < totalPage"
            @searchMore="searchMore"
            :loading="loading"
            :filterOptions="filterOptions"
            @filterOptionsChange="onFilterOptionsChange"
        />
        <BeforeSearch
            v-else
            :historyList="historyList"
            :hotSearchList="hotSearchList"
            @clearHistory="clearHistory"
            @search="quickSearch"
        />
    </div>
</template>

<script>
import SearchInput from '@/components/SearchInput/index.vue';
import BeforeSearch from './components/BeforeSearch/index.vue';
import SearchResult from './components/SearchResult/index.vue';
import { get, post } from '@/modules/request.js';
import { mapGetters, mapState } from 'vuex';

const SEARCH_HISTORY_STORAGE_KEY = 'search-history';

const getDefaultFilterOptions = () => {
    return {
        gender: -1,
        updateStatus: -1,
        labels: ['不限'],
        updateSince: -1,
    };
};

/**
 * 搜索接口
 * @param {*} data
 */
async function _search(data) {
    const res = await post({
        url: `${process.env.VUE_APP_DISTRIBUTION_HOST}/distribution/searching`,
        data,
    });

    if (res.code === 200) {
        return res.data;
    }

    return Promise.reject(res);
}

export default {
    name: 'Search',
    components: {
        SearchInput,
        BeforeSearch,
        SearchResult,
    },
    data() {
        return {
            searchValue: '',
            hotSearchList: [],
            historyList: [],
            searchResult: [],
            isSearched: false,
            page: 0,
            pageSize: 20,
            genderEnums: [],
            labels: [],
            updateStatusEnums: [],
            updateTimes: [],
            neverFiltered: true,
            totalCount: 0,
            totalPage: 0,
            loading: false,
            currentSearchValue: '',
            filterOptions: getDefaultFilterOptions(),
            from: '',
        };
    },
    computed: {
        ...mapGetters(['currentPage']),
        ...mapState(['gender']),
    },
    activated() {
        document.title = '搜索漫画';
        this.reportCommonPageOpen();
    },
    async created() {
        this.getSearchingOptions();
        await this.$nextTick();
        this.$refs.searchInput.focus();
    },
    methods: {
        reset() {
            this.neverFiltered = true;
            this.page = 0;
            this.filterOptions = getDefaultFilterOptions();
        },
        recordSearchHistory(value) {
            // 把 value 放在前面，等于排序了当前的搜索历史
            const list = [...new Set([value, ...this.historyList])];
            this.historyList = list;
            localStorage.setItem(SEARCH_HISTORY_STORAGE_KEY, JSON.stringify(list));
        },
        async normalSearch(data) {
            const {
                topicResults = [],
                totalCount,
                totalPage,
            } = await _search({
                page: this.page,
                pageSize: this.pageSize,
                searchText: this.searchValue,
                ...data,
            });

            this.searchResult = topicResults;
            this.isSearched = true;
            this.totalCount = totalCount;
            this.totalPage = totalPage;
            this.currentSearchValue = this.searchValue;

            this.recordSearchHistory(this.currentSearchValue);
        },

        async searchMore() {
            this.page++;
            this.loading = true;

            try {
                const { topicResults = [] } = await _search({
                    searchText: this.currentSearchValue,
                    page: this.page,
                    pageSize: this.pageSize,
                    ...this.filterOptions,
                });

                this.searchResult = this.searchResult.concat(topicResults);
            } catch (error) {
                console.error(error);
            }

            this.loading = false;
        },
        async onFilterOptionsChange(options) {
            this.page = 0;
            this.filterOptions = options;
            this.neverFiltered = false;
            try {
                await this.normalSearch({
                    ...this.getFilteredOptions(options),
                });
                this.reportSearchFormChange(options);
            } catch (error) {
                console.error(error);
            }
        },
        // TODO 简化M站暂无上报功能，后续会添加，暂时添加空函数处理差异
        reportToData() {
            // noop
        },
        report(data = {}) {
            this.reportToData('Search', {
                H5Page: location.href,
                SearchResults: this.totalCount,
                HWordsSource: this.searchValue,
                SearchKeyword: this.searchValue,
                SelectContent: '性别+不限,连载状态+不限,分类+不限,更新时间+不限',
                ...data,
            });
        },
        reportCommonPageOpen() {
            this.reportToData('CommonPageOpen', {
                CurPage: '搜索页',
                PrePage: this.from === '首页' ? this.currentPage : this.from,
            });
        },
        onClearSearchInput() {
            this.searchResult = [];
            this.isSearched = false;
        },
        async getSearchingOptions() {
            let historyList = localStorage.getItem(SEARCH_HISTORY_STORAGE_KEY);

            if (historyList) {
                try {
                    historyList = JSON.parse(historyList);
                } catch (error) {
                    console.error(error);
                    historyList = [];
                }
                this.historyList = historyList;
            }
            const params = {};

            if (this.gender !== null) {
                params.gender = this.gender + 1;
            }

            try {
                const res = await get({
                    url: `${process.env.VUE_APP_DISTRIBUTION_HOST}/distribution/searching/page`,
                    data: params,
                });

                if (res.code === 200) {
                    const {
                        hotRecs = [],
                        updateTimes = [],
                        updateStatusEnums = [],
                        genderEnums = [],
                        labels = [],
                    } = res.data;

                    this.hotSearchList = hotRecs;
                    this.updateTimes = updateTimes;
                    this.updateStatusEnums = updateStatusEnums;
                    this.genderEnums = genderEnums;
                    this.labels = labels;
                }
            } catch (error) {
                console.error(error);
            }
        },
        async clearHistory() {
            if (!this.historyList.length) {
                return;
            }

            localStorage.removeItem(SEARCH_HISTORY_STORAGE_KEY);

            this.historyList = [];
        },
        onBack() {
            this.$router.back();
        },
        async quickSearch(name) {
            this.searchValue = name;
            this.reset();
            this.$toast.show({ text: '加载中...', time: 30 * 1000 });

            try {
                await this.normalSearch();

                this.report({
                    HWordsSource: '搜索历史',
                });
            } catch (error) {
                console.error(error);
            }

            setTimeout(() => {
                this.$toast.hide();
            }, 600);
        },
        getFilteredOptions(options = {}) {
            const { labels } = options;

            const _searchValue = {};

            ['gender', 'updateStatus', 'updateSince'].forEach((key) => {
                if (options[key] > -1) {
                    _searchValue[key] = options[key];
                }
            });

            const filterLabels = labels.filter((item) => item !== '不限');

            if (filterLabels.length) {
                _searchValue.labels = JSON.stringify(filterLabels);
            }

            return _searchValue;
        },
        reportSearchFormChange(options = {}) {
            const { gender, updateStatus, labels, updateSince } = options;

            const genderText =
                '性别' +
                '+' +
                (gender === -1
                    ? '不限'
                    : this.genderEnums.find((item) => item.gender === gender)?.desc);
            const updateStatusText =
                '连载状态' +
                '+' +
                (updateStatus === -1
                    ? '不限'
                    : this.updateStatusEnums.find((item) => item.updateStatus === updateStatus)
                          ?.desc);
            const labelText = '分类' + '+' + labels.join(',');
            const updateSinceText =
                '更新时间' +
                '+' +
                (updateSince === -1
                    ? '不限'
                    : this.updateTimes.find((item) => item.updateSince === updateSince)?.desc);

            this.report({
                SelectContent: [genderText, updateStatusText, labelText, updateSinceText].join(','),
            });
        },
        async onSearch() {
            if (this.searchValue.length < 2) {
                this.$toast.show('请输入至少2个字');
                return;
            }

            if (this.searchValue.length > 20) {
                this.$toast.show('最多可输入20个字');
                return;
            }

            this.$toast.show({ text: '加载中...', time: 30 * 1000 });
            this.reset();
            try {
                await this.normalSearch();
                this.report({
                    HWordsSource: '无',
                });
            } catch (error) {
                console.error(error);
            }

            setTimeout(() => {
                this.$toast.hide();
            }, 600);
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.from = from.meta.trackName;
        });
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.wrapper {
    overflow-y: auto;
    height: 100vh;
}

.header {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .vws(padding, 32px);
    .vws(margin-bottom, 8px);
}

.back-icon {
    .vws(width, 48px);
    .vws(height, 48px);
    .vws(margin-right, 4px);

    display: flex;
    align-items: center;
    justify-self: center;

    /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
    & > img {
        width: 100%;
        height: 100%;
    }
}

.search-input {
    flex: 1;
    .vws(margin-right, 33px);
}

.search-button {
    .vws(font-size, 28px);

    font-family: 'PingFang SC';
    font-weight: 500;
    .vws(line-height, 32px);
}
</style>
