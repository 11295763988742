<template>
    <div class="tabs" data-cy="tabs-component">
        <div class="label">{{ label }}</div>
        <div class="tab-list">
            <div class="tab-item-wrapper" v-for="item in options" :key="item.value">
                <div
                    :class="[
                        'tab-item',
                        {
                            active: multiple
                                ? currentValue.includes(item.value)
                                : currentValue === item.value,
                        },
                    ]"
                    @click.passive="onClick(item.value)"
                >
                    {{ item.label }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Tabs',
    model: {
        prop: 'active',
        event: 'change',
    },
    props: {
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => [],
        },
        active: {
            type: [String, Number, Array],
            default: '',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentValue: this.active,
        };
    },
    watch: {
        active(val) {
            this.currentValue = val;
        },
    },
    methods: {
        onClick(value) {
            if (this.multiple) {
                let keys = this.currentValue;

                if (keys.includes(value)) {
                    keys = keys.filter((item) => item !== value);
                } else {
                    keys = keys.concat([value]);
                }

                this.$emit('change', keys);
                return;
            }

            this.$emit('change', value);
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

.tabs {
    display: flex;
}

.label {
    .vws(font-size, 24px);
    .vws(width, 96px);
    .vws(margin-right, 36px);

    flex: none;
    .vws(line-height, 50px);
}

.tab-list {
    display: flex;
    flex-wrap: wrap;
    .vws(margin, -12px);

    line-height: 1;
}

.tab-item-wrapper {
    .vws(padding, 12px);
}

.tab-item {
    .vws(border-radius, 50px);

    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #7f7f7f;
    background-color: transparent;
    line-height: 1;
    .vws(padding-top, 9px);
    .vws(padding-bottom, 9px);
    .vws(font-size, 26px);
    .vws(margin-right, 24px);
    .vws(padding-left, 16px);
    .vws(padding-right, 16px);
}

.tab-item.active {
    color: #333;
    background-color: #fef1f1;
}
</style>
