<template>
    <div class="empty">
        <div class="poster">
            <img src="../../assets/empty.png" />
        </div>
        <div class="desc">{{ title }}</div>
        <div class="button" @click="toServer">点击联系客服</div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '暂无数据，换个名称试试吧~',
        },
    },
    methods: {
        toServer() {
            this.$router.push({
                path: '/customer_service',
            });
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';

.empty {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;

    .poster {
        .vws(width, 250px);
        .vws(height, 250px);
        .vws(margin-bottom, 10px);

        img {
            width: 100%;
            height: 100%;
        }
    }

    .desc {
        .vws(font-size, 28px);

        color: #ffd3d3;
        .vws(margin-bottom, 54px);
    }

    .button {
        .vws(padding-top, 12px);
        .vws(padding-bottom, 12px);
        .vws(padding-left, 32px);
        .vws(padding-right, 32px);

        border: 1px solid #ccc;
        color: #222;
        line-height: 1;
        .vws(font-size, 26px);
        .vws(border-radius, 56px);
    }
}
</style>
