<template>
    <div class="comic-wrapper" @click="readComic" data-cy="comic-wrapper-component">
        <div class="comic">
            <div class="poster">
                <div class="poster-wrapper">
                    <img :data-src="compress(comic.coverImageUrl, 246, true)" v-lazyLoad />
                </div>
                <VipLabel v-if="comic.vipFree" />
            </div>

            <div class="info">
                <div class="title">
                    <span class="name">
                        {{ comic.topicAlias }}
                    </span>
                    <span
                        :class="[
                            'status',
                            {
                                progress: [0, 1].includes(comic.updateStatus),
                                finish: comic.updateStatus === 2,
                            },
                        ]"
                    >
                        {{ updateStatusText }}
                    </span>
                </div>
                <div class="labels">
                    <!-- 政策原因：注释 -->
                    <!-- {{ comic.labels.slice(0, 3).join(' ') }} -->
                </div>
                <div class="tag-wrapper">
                    <UpdateTag
                        class="tags"
                        :updateDesc="comic.updateDesc"
                        v-if="comic.updateDesc"
                    />
                </div>
            </div>
        </div>
        <div class="mask" v-if="clicked"></div>
    </div>
</template>
<script>
import UpdateTag from '@/components/UpdateTag';
import VipLabel from '@/components/label/index.vue';
import { compress } from '@kk/utils';

export default {
    name: 'Comic',
    components: {
        UpdateTag,
        VipLabel,
    },
    props: {
        clicked: {
            type: Boolean,
            default: false,
        },
        comic: {
            type: Object,
            required: true,
        },
    },
    computed: {
        updateStatusText() {
            const status = this.comic.updateStatus;

            if (status === 2) {
                return '已完结';
            }

            // 暂时停更和连载中都显示连载中
            if ([0, 1].includes(status)) {
                return '连载中';
            }

            return '';
        },
    },
    methods: {
        compress,
        readComic() {
            this.$emit('readComic', this.comic);
        },
    },
};
</script>
<style lang="less" scoped>
@import '~@/assets/vws.less';
@import '~@/assets/img.less';

.comic-wrapper {
    .vws(height, 180px);

    position: relative;
    display: flex;
    align-items: center;
}

.comic {
    .vws(height, 150px);

    display: flex;
}

.poster {
    position: relative;
    z-index: 2;
    overflow: hidden;
    height: 100%;
    .vws(width, 240px);
    .vws(border-radius, 12px);
    .vws(margin-right, 20px);

    .poster-wrapper {
        width: 100%;
        height: 100%;

        /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
        & > img {
            .global-cover-img;
            .vws(border-radius, 12px);
        }
    }
}

.info {
    .vws(padding-top, 7px);

    flex: 1;

    .title {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #333;
        .vws(font-size, 28px);
        .vws(margin-bottom, 19px);
        .vws(line-height, 34px);

        .name {
            .ellipsis-ln(1);
            .vws(margin-right, 19px);
        }

        .status {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            border: transparent 1px solid;
            box-sizing: border-box;
            flex: none;
            line-height: 1;
            scale: 0.9;
            .vws(font-size, 18px);
            .vws(border-radius, 6px);
            .vws(padding-left, 10px);
            .vws(padding-right, 10px);
            .vws(padding-top, 4px);
            .vws(padding-bottom, 4px);

            &.progress {
                border-color: #2cad66;
                color: #2cad66;
            }

            &.finish {
                border-color: #ff751a;
                color: #ff751a;
            }
        }
    }

    .labels {
        .vws(font-size, 22px);
        .vws(margin-bottom, 13px);
        .ellipsis-ln(1);

        color: #999;
        .vws(line-height, 28px);
    }

    .tag-wrapper {
        display: flex;
    }

    .tags {
        .vws(font-size, 20px);
        .vws(line-height, 20px);
        .vws(padding, 8px);

        display: inline-flex;
    }
}

.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: #f8f8f8;
    opacity: 0.4;
}
</style>
